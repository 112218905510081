<template>
  <el-dialog
    top="8px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('config_deadline.monthReportDeadline') }}
    </div>
    <div>
      <simple-form
        ref="yearForm"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{ span: 24 }"
        :view-flag="viewFlag"
      />
    </div>
    <div style="padding:12px 2px;">
      {{ $t('config_deadline.monthReportUploadTime') }}
    </div>
    <div>
      <simple-table
        :table-data="tableData"
        :table-column="tableColumn"
        :with-pagination="false"
      >
        <template
          slot="datePicker"
          slot-scope="scope"
        >
          <el-date-picker
            v-model="tableData[scope.$index][scope.column.property]"
            type="date"
            size="mini"
            value-format="yyyy-MM-dd"
            style="width:100%;"
            :default-value="defaultValue"
          />
        </template>
        <template
          slot="bizStatus"
          slot-scope="scope"
        >
          <el-select v-model="tableData[scope.$index][scope.column.property]">
            <el-option
              v-for="item in $getDictList('yes_no')"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
      </simple-table>
    </div>

    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
const BASEURL = {
  get: '/jlr/mt/monthReportConfig/list',
  save: '/jlr/mt/monthReportConfig/batch/update'
}
const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'orc', 'nov', 'dec']

export default {
  name: 'DeadlineDetail',
  props: {
    viewFlag: { type: Boolean },
    detailData: { type: Object },
    showFlag: { type: Boolean, default: false }
  },
  data () {
    return {
      formModel: {},
      tableData: []
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    defaultValue () {
      let defaultValue
      if (this.formModel.year) {
        defaultValue = this.formModel.year + '-01-01'
      } else {
        defaultValue = this.$moment().format('YYYY-MM-DD')
      }
      return defaultValue
    },
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },
    tableColumn () {
      return [
        {
          label: this.$t('config_deadline.month'),
          prop: 'monthTitle',
          width: 80
        },
        {
          label: this.$t('config_deadline.startTime'),
          prop: 'startTime',
          slotName: 'datePicker',
          required: true,
          minWidth: 200
        },
        {
          label: this.$t('config_deadline.endTime'),
          prop: 'endTime',
          slotName: 'datePicker',
          minWidth: 200
        },
        {
          label: this.$t('config_deadline.ifStart'),
          prop: 'bizStatus',
          slotName: 'bizStatus',
          width: 120
        }
      ]
    },
    formField () {
      return [
        {
          prop: 'year',
          label: this.$t('config_deadline.year'),
          type: 'Datepicker',
          component: {
            type: 'year',
            valueFormat: 'yyyy'
          },
          col: { xs: 8, sm: 8, md: 8 },
          rules: {
            trigger: 'blur',
            message: this.$t('validate.notBlank'),
            required: true
          },
          event: {
            change: val => {
              this.initTableData()
              this.handleDataGet()
            }
          }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    handleDataSubmit () {
      this.$refs.yearForm.$refs.simpleForm.validate(status => {
        if (status) {
          if (!this.checkDate()) return
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          let submitModel = []
          this.tableData.forEach(item => {
            if (item.startTime && item.endTime) {
              item.year = this.formModel.year
              delete item.monthTitle
              submitModel.push(item)
            }
          })
          this.$axios
            .post(BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDialogOpen () {
      this.formModel = { year: '2020' }
      this.initTableData()
      this.handleDataGet()
    },
    initTableData () {
      this.tableData = []
      for (let i in MONTHS) {
        this.tableData.push({
          monthTitle: this.$t('config_deadline.' + MONTHS[i]),
          month: i,
          bizStatus: 'N'
        })
      }
    },
    checkDate () {
      let flag = true
      this.tableData.forEach(data => {
        if ((data.startTime && !data.endTime) || (!data.startTime && data.endTime)) {
          this.$message({ type: 'error', message: this.$t('config_deadline.dateRangeTip') })
          flag = false
        }
      })
      return flag
    },
    handleDataGet () {
      const params = this.$utils.parseQueryCondition(this.formModel, this.formField)
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      params.page = { orderBy: 'month' }
      this.$axios
        .post(BASEURL.get, params)
        .then(resp => {
          const respData = resp.data
          const tableData = respData.list
          for (let i in tableData) {
            tableData[i].monthTitle = this.tableData[i].monthTitle
            this.$set(this.tableData, i, tableData[i])
          }
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleDialogClosed () {
      this.$refs.yearForm.resetFields()
      this.formModel = {}
    }
  }
}
</script>
